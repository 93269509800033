export const countryCodeList = [
  // {
  //   code: "cn",
  //   name: "China",
  //   dialCode: 86,
  //   phoneFormat: "131 2345 6789",
  //   CNName: "中国",
  // },
  {
    code: "hk",
    name: "Hong Kong",
    dialCode: 852,
    phoneFormat: "5123 4567",
    CNName: "中国香港",
  },
  {
    code: "mo",
    name: "Macao",
    dialCode: 853,
    phoneFormat: "6612 3456",
    CNName: "中国澳门",
  },
  // {
  //   code: "vn",
  //   name: "Vietnam (Việt Nam)",
  //   dialCode: 84,
  //   phoneFormat: "091 234 56 78",
  //   CNName: "越南",
  // },
  // {
  //   code: "id",
  //   name: "Indonesia",
  //   dialCode: 62,
  //   phoneFormat: "0812-345-678",
  //   CNName: "印度尼西亚",
  // },
  // {
  //   code: "th",
  //   name: "Thailand (ไทย)",
  //   dialCode: 66,
  //   phoneFormat: "081 234 5678",
  //   CNName: "泰国",
  // },
  // {
  //   code: "af",
  //   name: "Afghanistan (‫افغانستان‬‎)",
  //   dialCode: 93,
  //   phoneFormat: "070 123 4567",
  //   CNName: "阿富汗",
  // },
  // {
  //   code: "al",
  //   name: "Albania (Shqipëri)",
  //   dialCode: 355,
  //   phoneFormat: "066 123 4567",
  //   CNName: "阿尔巴尼亚",
  // },
  // {
  //   code: "dz",
  //   name: "Algeria (‫الجزائر‬‎)",
  //   dialCode: 213,
  //   phoneFormat: "0551 23 45 67",
  //   CNName: "阿尔及利亚",
  // },
  // {
  //   code: "as",
  //   name: "American Samoa",
  //   dialCode: 1684,
  //   phoneFormat: "(684) 733-1234",
  //   CNName: "美属萨摩亚",
  // },
  // {
  //   code: "ad",
  //   name: "Andorra",
  //   dialCode: 376,
  //   phoneFormat: "312 345",
  //   CNName: "安道尔",
  // },
  // {
  //   code: "ao",
  //   name: "Angola",
  //   dialCode: 244,
  //   phoneFormat: "923 123 456",
  //   CNName: "安哥拉",
  // },
  // {
  //   code: "ai",
  //   name: "Anguilla",
  //   dialCode: 1264,
  //   phoneFormat: "(264) 235-1234",
  //   CNName: "安圭拉",
  // },
  // {
  //   code: "ag",
  //   name: "Antigua and Barbuda",
  //   dialCode: 1268,
  //   phoneFormat: "(268) 464-1234",
  //   CNName: "安提瓜和巴布达",
  // },
  // {
  //   code: "ar",
  //   name: "Argentina",
  //   dialCode: 54,
  //   phoneFormat: "011 15-2345-6789",
  //   CNName: "阿根廷",
  // },
  // {
  //   code: "am",
  //   name: "Armenia (Հայաստան)",
  //   dialCode: 374,
  //   phoneFormat: "077 123456",
  //   CNName: "亚美尼亚",
  // },
  // {
  //   code: "aw",
  //   name: "Aruba",
  //   dialCode: 297,
  //   phoneFormat: "560 1234",
  //   CNName: "阿鲁巴",
  // },
  // {
  //   code: "am",
  //   name: "Ascension Island",
  //   dialCode: 290,
  //   phoneFormat: "",
  //   CNName: "阿森松岛",
  // },
  // {
  //   code: "au",
  //   name: "Australia",
  //   dialCode: 61,
  //   phoneFormat: "0412 345 678",
  //   CNName: "澳大利亚",
  // },
  // {
  //   code: "at",
  //   name: "Austria (Österreich)",
  //   dialCode: 43,
  //   phoneFormat: "0664 123456",
  //   CNName: "奥地利",
  // },
  // {
  //   code: "az",
  //   name: "Azerbaijan (Azərbaycan)",
  //   dialCode: 994,
  //   phoneFormat: "040 123 45 67",
  //   CNName: "阿塞拜疆",
  // },
  // {
  //   code: "bh",
  //   name: "Bahrain (‫البحرين‬‎)",
  //   dialCode: 973,
  //   phoneFormat: "3600 1234",
  //   CNName: "巴林",
  // },
  // {
  //   code: "bd",
  //   name: "Bangladesh (বাংলাদেশ)",
  //   dialCode: 880,
  //   phoneFormat: "01812-345678",
  //   CNName: "孟加拉国",
  // },
  // {
  //   code: "bb",
  //   name: "Barbados",
  //   dialCode: 1246,
  //   phoneFormat: "(246) 250-1234",
  //   CNName: "巴巴多斯",
  // },
  // {
  //   code: "by",
  //   name: "Belarus (Беларусь)",
  //   dialCode: 375,
  //   phoneFormat: "8 029 491-19-11",
  //   CNName: "白俄罗斯",
  // },
  // {
  //   code: "be",
  //   name: "Belgium (België)",
  //   dialCode: 32,
  //   phoneFormat: "0470 12 34 56",
  //   CNName: "比利时",
  // },
  // {
  //   code: "bz",
  //   name: "Belize",
  //   dialCode: 501,
  //   phoneFormat: "622-1234",
  //   CNName: "伯利兹",
  // },
  // {
  //   code: "bj",
  //   name: "Benin (Bénin)",
  //   dialCode: 229,
  //   phoneFormat: "90 01 12 34",
  //   CNName: "贝宁",
  // },
  // {
  //   code: "bm",
  //   name: "Bermuda",
  //   dialCode: 1441,
  //   phoneFormat: "(441) 370-1234",
  //   CNName: "百慕大",
  // },
  // {
  //   code: "bt",
  //   name: "Bhutan (འབྲུག)",
  //   dialCode: 975,
  //   phoneFormat: "17 12 34 56",
  //   CNName: "不丹",
  // },
  // {
  //   code: "bo",
  //   name: "Bolivia",
  //   dialCode: 591,
  //   phoneFormat: "71234567",
  //   CNName: "玻利维亚",
  // },
  // {
  //   code: "ba",
  //   name: "Bosnia and Herzegovina (Босна и Херцеговина)",
  //   dialCode: 387,
  //   phoneFormat: "061 123 456",
  //   CNName: "波黑",
  // },
  // {
  //   code: "bw",
  //   name: "Botswana",
  //   dialCode: 267,
  //   phoneFormat: "71 123 456",
  //   CNName: "博茨瓦纳",
  // },
  // {
  //   code: "br",
  //   name: "Brazil (Brasil)",
  //   dialCode: 55,
  //   phoneFormat: "(11) 96123-4567",
  //   CNName: "巴西",
  // },
  // {
  //   code: "io",
  //   name: "British Indian Ocean Territory",
  //   dialCode: 246,
  //   phoneFormat: "380 1234",
  //   CNName: "英属印度洋领地",
  // },
  // {
  //   code: "vg",
  //   name: "British Virgin Islands",
  //   dialCode: 1284,
  //   phoneFormat: "(284) 300-1234",
  //   CNName: "英属维尔京群岛",
  // },
  // {
  //   code: "bn",
  //   name: "Brunei Darussalam",
  //   dialCode: 673,
  //   phoneFormat: "712 3456",
  //   CNName: "文莱",
  // },
  // {
  //   code: "bg",
  //   name: "Bulgaria (България)",
  //   dialCode: 359,
  //   phoneFormat: "048 123 456",
  //   CNName: "保加利亚",
  // },
  // {
  //   code: "bf",
  //   name: "Burkina Faso",
  //   dialCode: 226,
  //   phoneFormat: "70 12 34 56",
  //   CNName: "布基纳法索",
  // },
  // {
  //   code: "bi",
  //   name: "Burundi (Uburundi)",
  //   dialCode: 257,
  //   phoneFormat: "79 56 12 34",
  //   CNName: "布隆迪",
  // },
  // {
  //   code: "kh",
  //   name: "Cambodia (កម្ពុជា)",
  //   dialCode: 855,
  //   phoneFormat: "091 234 567",
  //   CNName: "柬埔寨",
  // },
  // {
  //   code: "cm",
  //   name: "Cameroon (Cameroun)",
  //   dialCode: 237,
  //   phoneFormat: "6 71 23 45 67",
  //   CNName: "喀麦隆",
  // },
  // {
  //   code: "ca",
  //   name: "Canada",
  //   dialCode: 1,
  //   phoneFormat: "(204) 234-5678",
  //   CNName: "加拿大",
  // },
  // {
  //   code: "cv",
  //   name: "Cape Verde (Kabu Verdi)",
  //   dialCode: 238,
  //   phoneFormat: "991 12 34",
  //   CNName: "佛得角",
  // },
  // {
  //   code: "bq",
  //   name: "Caribbean Netherlands",
  //   dialCode: 599,
  //   phoneFormat: "318 1234",
  //   CNName: "荷兰加勒比区",
  // },
  // {
  //   code: "ky",
  //   name: "Cayman Islands",
  //   dialCode: 1345,
  //   phoneFormat: "(345) 323-1234",
  //   CNName: "开曼群岛",
  // },
  // {
  //   code: "cf",
  //   name: "Central African Republic (République centrafricaine)",
  //   dialCode: 236,
  //   phoneFormat: "70 01 23 45",
  //   CNName: "中非",
  // },
  // {
  //   code: "td",
  //   name: "Chad (Tchad)",
  //   dialCode: 235,
  //   phoneFormat: "63 01 23 45",
  //   CNName: "乍得",
  // },
  // {
  //   code: "cl",
  //   name: "Chile",
  //   dialCode: 56,
  //   phoneFormat: "09 6123 4567",
  //   CNName: "智利",
  // },
  // {
  //   code: "co",
  //   name: "Colombia",
  //   dialCode: 57,
  //   phoneFormat: "321 1234567",
  //   CNName: "哥伦比亚",
  // },
  // {
  //   code: "km",
  //   name: "Comoros (‫جزر القمر‬‎)",
  //   dialCode: 269,
  //   phoneFormat: "321 23 45",
  //   CNName: "科摩罗",
  // },
  // {
  //   code: "cd",
  //   name: "Congo (DRC) (Jamhuri ya Kidemokrasia ya Kongo)",
  //   dialCode: 243,
  //   phoneFormat: "0991 234 567",
  //   CNName: "刚果（布）",
  // },
  // {
  //   code: "cg",
  //   name: "Brazzaville)",
  //   dialCode: 242,
  //   phoneFormat: "06 123 4567",
  //   CNName: "刚果（金）-Congo (Republic) (Congo",
  // },
  // {
  //   code: "ck",
  //   name: "Cook Islands",
  //   dialCode: 682,
  //   phoneFormat: "71 234",
  //   CNName: "库克群岛",
  // },
  // {
  //   code: "cr",
  //   name: "Costa Rica",
  //   dialCode: 506,
  //   phoneFormat: "8312 3456",
  //   CNName: "哥斯达黎加",
  // },
  // {
  //   code: "ci",
  //   name: "Côte d’Ivoire",
  //   dialCode: 225,
  //   phoneFormat: "01 23 45 67",
  //   CNName: "科特迪瓦",
  // },
  // {
  //   code: "hr",
  //   name: "Croatia (Hrvatska)",
  //   dialCode: 385,
  //   phoneFormat: "091 234 5678",
  //   CNName: "克罗地亚",
  // },
  // {
  //   code: "cu",
  //   name: "Cuba",
  //   dialCode: 53,
  //   phoneFormat: "05 1234567",
  //   CNName: "古巴",
  // },
  // {
  //   code: "cw",
  //   name: "Curaçao",
  //   dialCode: 599,
  //   phoneFormat: "9 518 1234",
  //   CNName: "库拉索",
  // },
  // {
  //   code: "cy",
  //   name: "Cyprus (Κύπρος)",
  //   dialCode: 357,
  //   phoneFormat: "96 123456",
  //   CNName: "塞浦路斯",
  // },
  // {
  //   code: "cz",
  //   name: "Czech Republic (Česká republika)",
  //   dialCode: 420,
  //   phoneFormat: "601 123 456",
  //   CNName: "捷克",
  // },
  // {
  //   code: "dk",
  //   name: "Denmark (Danmark)",
  //   dialCode: 45,
  //   phoneFormat: "20 12 34 56",
  //   CNName: "丹麦",
  // },
  // {
  //   code: "dj",
  //   name: "Djibouti",
  //   dialCode: 253,
  //   phoneFormat: "77 83 10 01",
  //   CNName: "吉布提",
  // },
  // {
  //   code: "dm",
  //   name: "Dominica",
  //   dialCode: 1767,
  //   phoneFormat: "(767) 225-1234",
  //   CNName: "多米尼克",
  // },
  // {
  //   code: "do",
  //   name: "Dominican Republic (República Dominicana)",
  //   dialCode: 1,
  //   phoneFormat: "(809) 234-5678",
  //   CNName: "多米尼加",
  // },
  // {
  //   code: "ec",
  //   name: "Ecuador",
  //   dialCode: 593,
  //   phoneFormat: "099 123 4567",
  //   CNName: "厄瓜多尔",
  // },
  // {
  //   code: "eg",
  //   name: "Egypt (‫مصر‬‎)",
  //   dialCode: 20,
  //   phoneFormat: "0100 123 4567",
  //   CNName: "埃及",
  // },
  // {
  //   code: "sv",
  //   name: "El Salvador",
  //   dialCode: 503,
  //   phoneFormat: "7012 3456",
  //   CNName: "萨尔瓦多",
  // },
  // {
  //   code: "gq",
  //   name: "Equatorial Guinea (Guinea Ecuatorial)",
  //   dialCode: 240,
  //   phoneFormat: "222 123 456",
  //   CNName: "赤道几内亚",
  // },
  // {
  //   code: "er",
  //   name: "Eritrea",
  //   dialCode: 291,
  //   phoneFormat: "07 123 456",
  //   CNName: "厄立特里亚",
  // },
  // {
  //   code: "ee",
  //   name: "Estonia (Eesti)",
  //   dialCode: 372,
  //   phoneFormat: "5123 4567",
  //   CNName: "爱沙尼亚",
  // },
  // {
  //   code: "et",
  //   name: "Ethiopia",
  //   dialCode: 251,
  //   phoneFormat: "091 123 4567",
  //   CNName: "埃塞俄比亚",
  // },
  // {
  //   code: "fk",
  //   name: "Falkland Islands (Islas Malvinas)",
  //   dialCode: 500,
  //   phoneFormat: "51234",
  //   CNName: "福克兰群岛（马尔维纳斯）",
  // },
  // {
  //   code: "fo",
  //   name: "Faroe Islands (Føroyar)",
  //   dialCode: 298,
  //   phoneFormat: "211234",
  //   CNName: "法罗群岛",
  // },
  // {
  //   code: "fj",
  //   name: "Fiji",
  //   dialCode: 679,
  //   phoneFormat: "701 2345",
  //   CNName: "斐济",
  // },
  // {
  //   code: "fi",
  //   name: "Finland (Suomi)",
  //   dialCode: 358,
  //   phoneFormat: "041 2345678",
  //   CNName: "芬兰",
  // },
  // {
  //   code: "fr",
  //   name: "France",
  //   dialCode: 33,
  //   phoneFormat: "06 12 34 56 78",
  //   CNName: "法国",
  // },
  // {
  //   code: "gf",
  //   name: "French Guiana (Guyane française)",
  //   dialCode: 594,
  //   phoneFormat: "0694 20 12 34",
  //   CNName: "法属圭亚那",
  // },
  // {
  //   code: "pf",
  //   name: "French Polynesia (Polynésie française)",
  //   dialCode: 689,
  //   phoneFormat: "87 12 34 56",
  //   CNName: "法属波利尼西亚",
  // },
  // {
  //   code: "ga",
  //   name: "Gabon",
  //   dialCode: 241,
  //   phoneFormat: "06 03 12 34",
  //   CNName: "加蓬",
  // },
  // {
  //   code: "ge",
  //   name: "Georgia (საქართველო)",
  //   dialCode: 995,
  //   phoneFormat: "555 12 34 56",
  //   CNName: "格鲁吉亚",
  // },
  // {
  //   code: "de",
  //   name: "Germany (Deutschland)",
  //   dialCode: 49,
  //   phoneFormat: "01512 3456789",
  //   CNName: "德国",
  // },
  // {
  //   code: "gh",
  //   name: "Ghana (Gaana)",
  //   dialCode: 233,
  //   phoneFormat: "023 123 4567",
  //   CNName: "加纳",
  // },
  // {
  //   code: "gi",
  //   name: "Gibraltar",
  //   dialCode: 350,
  //   phoneFormat: "57123456",
  //   CNName: "直布罗陀",
  // },
  // {
  //   code: "gr",
  //   name: "Greece (Ελλάδα)",
  //   dialCode: 30,
  //   phoneFormat: "691 234 5678",
  //   CNName: "希腊",
  // },
  // {
  //   code: "gl",
  //   name: "Greenland (Kalaallit Nunaat)",
  //   dialCode: 299,
  //   phoneFormat: "22 12 34",
  //   CNName: "格陵兰",
  // },
  // {
  //   code: "gd",
  //   name: "Grenada",
  //   dialCode: 1473,
  //   phoneFormat: "(473) 403-1234",
  //   CNName: "格林纳达",
  // },
  // {
  //   code: "gp",
  //   name: "Guadeloupe",
  //   dialCode: 590,
  //   phoneFormat: "0690 30-1234",
  //   CNName: "瓜德罗普",
  // },
  // {
  //   code: "gu",
  //   name: "Guam",
  //   dialCode: 1671,
  //   phoneFormat: "(671) 300-1234",
  //   CNName: "关岛",
  // },
  // {
  //   code: "gt",
  //   name: "Guatemala",
  //   dialCode: 502,
  //   phoneFormat: "5123 4567",
  //   CNName: "危地马拉",
  // },
  // {
  //   code: "gg",
  //   name: "Guernsey",
  //   dialCode: 1481,
  //   phoneFormat: "07781 123456",
  //   CNName: "根西岛",
  // },
  // {
  //   code: "gn",
  //   name: "Guinea (Guinée)",
  //   dialCode: 224,
  //   phoneFormat: "601 12 34 56",
  //   CNName: "几内亚",
  // },
  // {
  //   code: "gw",
  //   name: "Bissau (Guiné Bissau)",
  //   dialCode: 245,
  //   phoneFormat: "955 012 345",
  //   CNName: "几内亚比绍-Guinea",
  // },
  // {
  //   code: "gy",
  //   name: "Guyana",
  //   dialCode: 592,
  //   phoneFormat: "609 1234",
  //   CNName: "圭亚那",
  // },
  // {
  //   code: "ht",
  //   name: "Haiti",
  //   dialCode: 509,
  //   phoneFormat: "34 10 1234",
  //   CNName: "海地",
  // },
  // {
  //   code: "hn",
  //   name: "Honduras",
  //   dialCode: 504,
  //   phoneFormat: "9123-4567",
  //   CNName: "洪都拉斯",
  // },
  // {
  //   code: "hu",
  //   name: "Hungary (Magyarország)",
  //   dialCode: 36,
  //   phoneFormat: "(20) 123 4567",
  //   CNName: "匈牙利",
  // },
  // {
  //   code: "is",
  //   name: "Iceland (Ísland)",
  //   dialCode: 354,
  //   phoneFormat: "611 1234",
  //   CNName: "冰岛",
  // },
  // {
  //   code: "in",
  //   name: "India (भारत)",
  //   dialCode: 91,
  //   phoneFormat: "099876 54321",
  //   CNName: "印度",
  // },
  // {
  //   code: "ir",
  //   name: "Iran (‫ایران‬‎)",
  //   dialCode: 98,
  //   phoneFormat: "0912 345 6789",
  //   CNName: "伊朗",
  // },
  // {
  //   code: "iq",
  //   name: "Iraq (‫العراق‬‎)",
  //   dialCode: 964,
  //   phoneFormat: "0791 234 5678",
  //   CNName: "伊拉克",
  // },
  // {
  //   code: "ie",
  //   name: "Ireland",
  //   dialCode: 353,
  //   phoneFormat: "085 012 3456",
  //   CNName: "爱尔兰",
  // },
  // {
  //   code: "im",
  //   name: "Isle of Man",
  //   dialCode: 44,
  //   phoneFormat: "07924 123456",
  //   CNName: "马恩岛",
  // },
  // {
  //   code: "il",
  //   name: "Israel (‫ישראל‬‎)",
  //   dialCode: 972,
  //   phoneFormat: "050-123-4567",
  //   CNName: "以色列",
  // },
  // {
  //   code: "it",
  //   name: "Italy (Italia)",
  //   dialCode: 39,
  //   phoneFormat: "312 345 6789",
  //   CNName: "意大利",
  // },
  // {
  //   code: "jm",
  //   name: "Jamaica",
  //   dialCode: 1876,
  //   phoneFormat: "(876) 210-1234",
  //   CNName: "牙买加",
  // },
  // {
  //   code: "jp",
  //   name: "Japan (にほんこく)",
  //   dialCode: 81,
  //   phoneFormat: "090-1234-5678",
  //   CNName: "日本",
  // },
  // {
  //   code: "je",
  //   name: "Jersey",
  //   dialCode: 44,
  //   phoneFormat: "07797 123456",
  //   CNName: "泽西岛",
  // },
  // {
  //   code: "jo",
  //   name: "Jordan (‫الأردن‬‎)",
  //   dialCode: 962,
  //   phoneFormat: "07 9012 3456",
  //   CNName: "约旦",
  // },
  // {
  //   code: "kz",
  //   name: "Kazakhstan (Казахстан)",
  //   dialCode: 7,
  //   phoneFormat: "8 (771) 000 9998",
  //   CNName: "哈萨克斯坦",
  // },
  // {
  //   code: "ke",
  //   name: "Kenya",
  //   dialCode: 254,
  //   phoneFormat: "0712 123456",
  //   CNName: "肯尼亚",
  // },
  // {
  //   code: "ki",
  //   name: "Kiribati",
  //   dialCode: 686,
  //   phoneFormat: "72012345",
  //   CNName: "基里巴斯",
  // },
  // {
  //   code: "xk",
  //   name: "Kosovo",
  //   dialCode: 383,
  //   phoneFormat: "",
  //   CNName: "科索沃",
  // },
  // {
  //   code: "kw",
  //   name: "Kuwait (‫الكويت‬‎)",
  //   dialCode: 965,
  //   phoneFormat: "500 12345",
  //   CNName: "科威特",
  // },
  // {
  //   code: "kg",
  //   name: "Kyrgyzstan (Кыргызстан)",
  //   dialCode: 996,
  //   phoneFormat: "0700 123 456",
  //   CNName: "吉尔吉斯斯坦",
  // },
  // {
  //   code: "la",
  //   name: "Laos (ລາວ)",
  //   dialCode: 856,
  //   phoneFormat: "020 23 123 456",
  //   CNName: "老挝",
  // },
  // {
  //   code: "lv",
  //   name: "Latvia (Latvija)",
  //   dialCode: 371,
  //   phoneFormat: "21 234 567",
  //   CNName: "拉脱维亚",
  // },
  // {
  //   code: "lb",
  //   name: "Lebanon (‫لبنان‬‎)",
  //   dialCode: 961,
  //   phoneFormat: "71 123 456",
  //   CNName: "黎巴嫩",
  // },
  // {
  //   code: "ls",
  //   name: "Lesotho",
  //   dialCode: 266,
  //   phoneFormat: "5012 3456",
  //   CNName: "莱索托",
  // },
  // {
  //   code: "lr",
  //   name: "Liberia",
  //   dialCode: 231,
  //   phoneFormat: "077 012 3456",
  //   CNName: "利比里亚",
  // },
  // {
  //   code: "ly",
  //   name: "Libya (‫ليبيا‬‎)",
  //   dialCode: 218,
  //   phoneFormat: "091-2345678",
  //   CNName: "利比亚",
  // },
  // {
  //   code: "li",
  //   name: "Liechtenstein",
  //   dialCode: 423,
  //   phoneFormat: "660 234 567",
  //   CNName: "列支敦士登",
  // },
  // {
  //   code: "lt",
  //   name: "Lithuania (Lietuva)",
  //   dialCode: 370,
  //   phoneFormat: "(8-612) 34567",
  //   CNName: "立陶宛",
  // },
  // {
  //   code: "lu",
  //   name: "Luxembourg",
  //   dialCode: 352,
  //   phoneFormat: "628 123 456",
  //   CNName: "卢森堡",
  // },
  // {
  //   code: "mk",
  //   name: "Macedonia (FYROM) (Македонија)",
  //   dialCode: 389,
  //   phoneFormat: "072 345 678",
  //   CNName: "前南马其顿",
  // },
  // {
  //   code: "mg",
  //   name: "Madagascar (Madagasikara)",
  //   dialCode: 261,
  //   phoneFormat: "032 12 345 67",
  //   CNName: "马达加斯加",
  // },
  // {
  //   code: "mw",
  //   name: "Malawi",
  //   dialCode: 265,
  //   phoneFormat: "0991 23 45 67",
  //   CNName: "马拉维",
  // },
  // {
  //   code: "my",
  //   name: "Malaysia",
  //   dialCode: 60,
  //   phoneFormat: "012-345 6789",
  //   CNName: "马来西亚",
  // },
  // {
  //   code: "mv",
  //   name: "Maldives",
  //   dialCode: 960,
  //   phoneFormat: "771-2345",
  //   CNName: "马尔代夫",
  // },
  // {
  //   code: "ml",
  //   name: "Mali",
  //   dialCode: 223,
  //   phoneFormat: "65 01 23 45",
  //   CNName: "马里",
  // },
  // {
  //   code: "mt",
  //   name: "Malta",
  //   dialCode: 356,
  //   phoneFormat: "9696 1234",
  //   CNName: "马耳他",
  // },
  // {
  //   code: "mh",
  //   name: "Marshall Islands",
  //   dialCode: 692,
  //   phoneFormat: "235-1234",
  //   CNName: "马绍尔群岛",
  // },
  // {
  //   code: "mq",
  //   name: "Martinique",
  //   dialCode: 596,
  //   phoneFormat: "0696 20 12 34",
  //   CNName: "马提尼克",
  // },
  // {
  //   code: "mr",
  //   name: "Mauritania (‫موريتانيا‬‎)",
  //   dialCode: 222,
  //   phoneFormat: "22 12 34 56",
  //   CNName: "毛里塔尼亚",
  // },
  // {
  //   code: "mu",
  //   name: "Mauritius (Moris)",
  //   dialCode: 230,
  //   phoneFormat: "5251 2345",
  //   CNName: "毛里求斯",
  // },
  // {
  //   code: "yt",
  //   name: "Mayotte",
  //   dialCode: 262,
  //   phoneFormat: "0639 12 34 56",
  //   CNName: "马约特",
  // },
  // {
  //   code: "mx",
  //   name: "Mexico (México)",
  //   dialCode: 52,
  //   phoneFormat: "044 222 123 4567",
  //   CNName: "墨西哥",
  // },
  // {
  //   code: "fm",
  //   name: "Micronesia",
  //   dialCode: 691,
  //   phoneFormat: "350 1234",
  //   CNName: "密克罗尼西亚",
  // },
  // {
  //   code: "md",
  //   name: "Moldova (Republica Moldova)",
  //   dialCode: 373,
  //   phoneFormat: "0621 12 345",
  //   CNName: "摩尔多瓦",
  // },
  // {
  //   code: "mc",
  //   name: "Monaco",
  //   dialCode: 377,
  //   phoneFormat: "06 12 34 56 78",
  //   CNName: "摩纳哥",
  // },
  // {
  //   code: "mn",
  //   name: "Mongolia (Монгол)",
  //   dialCode: 976,
  //   phoneFormat: "8812 3456",
  //   CNName: "蒙古",
  // },
  // {
  //   code: "me",
  //   name: "Montenegro (Crna Gora)",
  //   dialCode: 382,
  //   phoneFormat: "067 622 901",
  //   CNName: "黑山",
  // },
  // {
  //   code: "ms",
  //   name: "Montserrat",
  //   dialCode: 1664,
  //   phoneFormat: "(664) 492-3456",
  //   CNName: "蒙特塞拉特",
  // },
  // {
  //   code: "ma",
  //   name: "Morocco (‫المغرب‬‎)",
  //   dialCode: 212,
  //   phoneFormat: "0650-123456",
  //   CNName: "摩洛哥",
  // },
  // {
  //   code: "mz",
  //   name: "Mozambique (Moçambique)",
  //   dialCode: 258,
  //   phoneFormat: "82 123 4567",
  //   CNName: "莫桑比克",
  // },
  // {
  //   code: "mm",
  //   name: "Myanmar (Burma) (မြန်မာ)",
  //   dialCode: 95,
  //   phoneFormat: "09 212 3456",
  //   CNName: "缅甸",
  // },
  // {
  //   code: "na",
  //   name: "Namibia (Namibië)",
  //   dialCode: 264,
  //   phoneFormat: "081 123 4567",
  //   CNName: "纳米尼亚",
  // },
  // {
  //   code: "nr",
  //   name: "Nauru",
  //   dialCode: 674,
  //   phoneFormat: "555 1234",
  //   CNName: "瑙鲁",
  // },
  // {
  //   code: "np",
  //   name: "Nepal (नेपाल)",
  //   dialCode: 977,
  //   phoneFormat: "984-1234567",
  //   CNName: "尼泊尔",
  // },
  // {
  //   code: "nl",
  //   name: "Netherlands (Nederland)",
  //   dialCode: 31,
  //   phoneFormat: "06 12345678",
  //   CNName: "荷兰",
  // },
  // {
  //   code: "nc",
  //   name: "Calédonie)",
  //   dialCode: 687,
  //   phoneFormat: "75.12.34",
  //   CNName: "新喀里多尼亚-New Caledonia (Nouvelle",
  // },
  // {
  //   code: "nz",
  //   name: "New Zealand",
  //   dialCode: 64,
  //   phoneFormat: "021 123 4567",
  //   CNName: "新西兰",
  // },
  // {
  //   code: "ni",
  //   name: "Nicaragua",
  //   dialCode: 505,
  //   phoneFormat: "8123 4567",
  //   CNName: "尼加拉瓜",
  // },
  // {
  //   code: "ne",
  //   name: "Niger (Nijar)",
  //   dialCode: 227,
  //   phoneFormat: "93 12 34 56",
  //   CNName: "尼日尔",
  // },
  // {
  //   code: "ng",
  //   name: "Nigeria",
  //   dialCode: 234,
  //   phoneFormat: "0802 123 4567",
  //   CNName: "尼日利亚",
  // },
  // {
  //   code: "nu",
  //   name: "Niue",
  //   dialCode: 683,
  //   phoneFormat: "1234",
  //   CNName: "纽埃",
  // },
  // {
  //   code: "nf",
  //   name: "Norfolk Island",
  //   dialCode: 672,
  //   phoneFormat: "3 81234",
  //   CNName: "诺福克岛",
  // },
  // {
  //   code: "kp",
  //   name: "North Korea (조선민주주의인민공화국)",
  //   dialCode: 850,
  //   phoneFormat: "0192 123 4567",
  //   CNName: "朝鲜",
  // },
  // {
  //   code: "mp",
  //   name: "Northern Mariana Islands",
  //   dialCode: 1670,
  //   phoneFormat: "(670) 234-5678",
  //   CNName: "北马里亚纳",
  // },
  // {
  //   code: "no",
  //   name: "Norway (Norge)",
  //   dialCode: 47,
  //   phoneFormat: "406 12 345",
  //   CNName: "挪威",
  // },
  // {
  //   code: "om",
  //   name: "Oman (‫عُمان‬‎)",
  //   dialCode: 968,
  //   phoneFormat: "9212 3456",
  //   CNName: "阿曼",
  // },
  // {
  //   code: "pk",
  //   name: "Pakistan (‫پاکستان‬‎)",
  //   dialCode: 92,
  //   phoneFormat: "0301 2345678",
  //   CNName: "巴基斯坦",
  // },
  // {
  //   code: "pw",
  //   name: "Palau",
  //   dialCode: 680,
  //   phoneFormat: "620 1234",
  //   CNName: "帕劳",
  // },
  // {
  //   code: "ps",
  //   name: "Palestine (‫فلسطين‬‎)",
  //   dialCode: 970,
  //   phoneFormat: "0599 123 456",
  //   CNName: "巴勒斯坦",
  // },
  // {
  //   code: "pa",
  //   name: "Panama (Panamá)",
  //   dialCode: 507,
  //   phoneFormat: "6001-2345",
  //   CNName: "巴拿马",
  // },
  // {
  //   code: "pg",
  //   name: "Papua New Guinea",
  //   dialCode: 675,
  //   phoneFormat: "681 2345",
  //   CNName: "巴布亚新几内亚",
  // },
  // {
  //   code: "py",
  //   name: "Paraguay",
  //   dialCode: 595,
  //   phoneFormat: "0961 456789",
  //   CNName: "巴拉圭",
  // },
  // {
  //   code: "pe",
  //   name: "Peru (Perú)",
  //   dialCode: 51,
  //   phoneFormat: "912 345 678",
  //   CNName: "秘鲁",
  // },
  // {
  //   code: "ph",
  //   name: "Philippines",
  //   dialCode: 63,
  //   phoneFormat: "0905 123 4567",
  //   CNName: "菲律宾",
  // },
  // {
  //   code: "pl",
  //   name: "Poland (Polska)",
  //   dialCode: 48,
  //   phoneFormat: "512 345 678",
  //   CNName: "皮特凯恩",
  // },
  // {
  //   code: "pt",
  //   name: "Portugal",
  //   dialCode: 351,
  //   phoneFormat: "912 345 678",
  //   CNName: "葡萄牙",
  // },
  // {
  //   code: "pr",
  //   name: "Puerto Rico",
  //   dialCode: 1,
  //   phoneFormat: "(787) 234-5678",
  //   CNName: "波多黎各",
  // },
  // {
  //   code: "qa",
  //   name: "Qatar (‫قطر‬‎)",
  //   dialCode: 974,
  //   phoneFormat: "3312 3456",
  //   CNName: "卡塔尔",
  // },
  // {
  //   code: "re",
  //   name: "Réunion (La Réunion)",
  //   dialCode: 262,
  //   phoneFormat: "0692 12 34 56",
  //   CNName: "留尼汪",
  // },
  // {
  //   code: "ro",
  //   name: "Romania (România)",
  //   dialCode: 40,
  //   phoneFormat: "0712 345 678",
  //   CNName: "罗马尼亚",
  // },
  // {
  //   code: "ru",
  //   name: "Russia (Россия)",
  //   dialCode: 7,
  //   phoneFormat: "8 (912) 345-67-89",
  //   CNName: "俄罗斯",
  // },
  // {
  //   code: "rw",
  //   name: "Rwanda",
  //   dialCode: 250,
  //   phoneFormat: "0720 123 456",
  //   CNName: "卢旺达",
  // },
  // {
  //   code: "sh",
  //   name: "Saint Helena",
  //   dialCode: 290,
  //   phoneFormat: "51234",
  //   CNName: "圣赫勒拿",
  // },
  // {
  //   code: "kn",
  //   name: "Saint Kitts and Nevis",
  //   dialCode: 1869,
  //   phoneFormat: "(869) 765-2917",
  //   CNName: "圣基茨和尼维斯",
  // },
  // {
  //   code: "lc",
  //   name: "Saint Lucia",
  //   dialCode: 1758,
  //   phoneFormat: "(758) 284-5678",
  //   CNName: "圣卢西亚",
  // },
  // {
  //   code: "mf",
  //   name: "Martin)",
  //   dialCode: 590,
  //   phoneFormat: "0690 30-1234",
  //   CNName: "圣马丁岛-Saint Martin (Saint",
  // },
  // {
  //   code: "pm",
  //   name: "Miquelon)",
  //   dialCode: 508,
  //   phoneFormat: "055 12 34",
  //   CNName: "圣皮埃尔和密克隆-Saint Pierre and Miquelon (Saint-Pierre-et",
  // },
  // {
  //   code: "vc",
  //   name: "Saint Vincent and the Grenadines",
  //   dialCode: 1784,
  //   phoneFormat: "(784) 430-1234",
  //   CNName: "圣文森特和格林纳丁斯",
  // },
  // {
  //   code: "ws",
  //   name: "Samoa",
  //   dialCode: 685,
  //   phoneFormat: "601234",
  //   CNName: "萨摩亚",
  // },
  // {
  //   code: "sm",
  //   name: "San Marino",
  //   dialCode: 378,
  //   phoneFormat: "66 66 12 12",
  //   CNName: "圣马力诺",
  // },
  // {
  //   code: "st",
  //   name: "São Tomé and Príncipe (São Tomé e Príncipe)",
  //   dialCode: 239,
  //   phoneFormat: "981 2345",
  //   CNName: "圣多美和普林西比",
  // },
  // {
  //   code: "sa",
  //   name: "Saudi Arabia (‫المملكة العربية السعودية‬‎)",
  //   dialCode: 966,
  //   phoneFormat: "051 234 5678",
  //   CNName: "沙特阿拉伯",
  // },
  // {
  //   code: "sn",
  //   name: "Senegal (Sénégal)",
  //   dialCode: 221,
  //   phoneFormat: "70 123 45 67",
  //   CNName: "塞内加尔",
  // },
  // {
  //   code: "rs",
  //   name: "Serbia (Србија)",
  //   dialCode: 381,
  //   phoneFormat: "060 1234567",
  //   CNName: "塞尔维亚",
  // },
  // {
  //   code: "sc",
  //   name: "Seychelles",
  //   dialCode: 248,
  //   phoneFormat: "2 510 123",
  //   CNName: "塞舌尔",
  // },
  // {
  //   code: "sl",
  //   name: "Sierra Leone",
  //   dialCode: 232,
  //   phoneFormat: "(025) 123456",
  //   CNName: "塞拉利昂",
  // },
  // {
  //   code: "sg",
  //   name: "Singapore",
  //   dialCode: 65,
  //   phoneFormat: "8123 4567",
  //   CNName: "新加坡",
  // },
  // {
  //   code: "sx",
  //   name: "Sint Maarten",
  //   dialCode: 1721,
  //   phoneFormat: "(721) 520-5678",
  //   CNName: "荷属圣马丁",
  // },
  // {
  //   code: "sk",
  //   name: "Slovakia (Slovensko)",
  //   dialCode: 421,
  //   phoneFormat: "0912 123 456",
  //   CNName: "斯洛伐克",
  // },
  // {
  //   code: "si",
  //   name: "Slovenia (Slovenija)",
  //   dialCode: 386,
  //   phoneFormat: "031 234 567",
  //   CNName: "斯洛文尼亚",
  // },
  // {
  //   code: "sb",
  //   name: "Solomon Islands",
  //   dialCode: 677,
  //   phoneFormat: "74 21234",
  //   CNName: "所罗门群岛",
  // },
  // {
  //   code: "so",
  //   name: "Somalia (Soomaaliya)",
  //   dialCode: 252,
  //   phoneFormat: "7 1123456",
  //   CNName: "索马里",
  // },
  // {
  //   code: "za",
  //   name: "South Africa",
  //   dialCode: 27,
  //   phoneFormat: "071 123 4567",
  //   CNName: "南非",
  // },
  // {
  //   code: "kr",
  //   name: "South Korea (대한민국)",
  //   dialCode: 82,
  //   phoneFormat: "010-0000-0000",
  //   CNName: "韩国",
  // },
  // {
  //   code: "ss",
  //   name: "South Sudan (‫جنوب السودان‬‎)",
  //   dialCode: 211,
  //   phoneFormat: "0977 123 456",
  //   CNName: "南苏丹",
  // },
  // {
  //   code: "es",
  //   name: "Spain (España)",
  //   dialCode: 34,
  //   phoneFormat: "612 34 56 78",
  //   CNName: "西班牙",
  // },
  // {
  //   code: "lk",
  //   name: "Sri Lanka (ශ්‍රී ලංකාව)",
  //   dialCode: 94,
  //   phoneFormat: "071 234 5678",
  //   CNName: "斯里兰卡",
  // },
  // {
  //   code: "sd",
  //   name: "Sudan (‫السودان‬‎)",
  //   dialCode: 249,
  //   phoneFormat: "091 123 1234",
  //   CNName: "苏丹",
  // },
  // {
  //   code: "sr",
  //   name: "Suriname",
  //   dialCode: 597,
  //   phoneFormat: "741-2345",
  //   CNName: "苏里南",
  // },
  // {
  //   code: "sj",
  //   name: "Svalbard and Jan Mayen",
  //   dialCode: 47,
  //   phoneFormat: "412 34 567",
  //   CNName: "斯瓦尔巴岛和扬马延岛",
  // },
  // {
  //   code: "sz",
  //   name: "Swaziland",
  //   dialCode: 268,
  //   phoneFormat: "7612 3456",
  //   CNName: "斯威士兰",
  // },
  // {
  //   code: "se",
  //   name: "Sweden (Sverige)",
  //   dialCode: 46,
  //   phoneFormat: "070-123 45 67",
  //   CNName: "瑞典",
  // },
  // {
  //   code: "ch",
  //   name: "Switzerland (Schweiz)",
  //   dialCode: 41,
  //   phoneFormat: "078 123 45 67",
  //   CNName: "瑞士",
  // },
  // {
  //   code: "sy",
  //   name: "Syria (‫سوريا‬‎)",
  //   dialCode: 963,
  //   phoneFormat: "0944 567 890",
  //   CNName: "叙利亚",
  // },
  // {
  //   code: "tw",
  //   name: "Taiwan",
  //   dialCode: 886,
  //   phoneFormat: "0912 345 678",
  //   CNName: "中国台湾",
  // },
  // {
  //   code: "tj",
  //   name: "Tajikistan",
  //   dialCode: 992,
  //   phoneFormat: "(8) 917 12 3456",
  //   CNName: "塔吉克斯坦",
  // },
  // {
  //   code: "tz",
  //   name: "Tanzania",
  //   dialCode: 255,
  //   phoneFormat: "0621 234 567",
  //   CNName: "坦桑尼亚",
  // },
  // {
  //   code: "bs",
  //   name: "The Bahamas",
  //   dialCode: 1242,
  //   phoneFormat: "(242) 359-1234",
  //   CNName: "巴哈马",
  // },
  // {
  //   code: "gm",
  //   name: "The Gambia",
  //   dialCode: 220,
  //   phoneFormat: "301 2345",
  //   CNName: "冈比亚",
  // },
  // {
  //   code: "tl",
  //   name: "Leste",
  //   dialCode: 670,
  //   phoneFormat: "7721 2345",
  //   CNName: "东帝汶-Timor",
  // },
  // {
  //   code: "tg",
  //   name: "Togo",
  //   dialCode: 228,
  //   phoneFormat: "90 11 23 45",
  //   CNName: "多哥",
  // },
  // {
  //   code: "tk",
  //   name: "Tokelau",
  //   dialCode: 690,
  //   phoneFormat: "7290",
  //   CNName: "托克劳",
  // },
  // {
  //   code: "to",
  //   name: "Tonga",
  //   dialCode: 676,
  //   phoneFormat: "771 5123",
  //   CNName: "汤加",
  // },
  // {
  //   code: "tt",
  //   name: "Trinidad and Tobago",
  //   dialCode: 1868,
  //   phoneFormat: "(868) 291-1234",
  //   CNName: "特立尼达和多巴哥",
  // },
  // {
  //   code: "tn",
  //   name: "Tunisia (‫تونس‬‎)",
  //   dialCode: 216,
  //   phoneFormat: "20 123 456",
  //   CNName: "突尼斯",
  // },
  // {
  //   code: "tr",
  //   name: "Turkey (Türkiye)",
  //   dialCode: 90,
  //   phoneFormat: "0501 234 56 78",
  //   CNName: "土耳其",
  // },
  // {
  //   code: "tm",
  //   name: "Turkmenistan",
  //   dialCode: 993,
  //   phoneFormat: "8 66 123456",
  //   CNName: "土库曼斯坦",
  // },
  // {
  //   code: "tc",
  //   name: "Turks and Caicos Islands",
  //   dialCode: 1649,
  //   phoneFormat: "(649) 231-1234",
  //   CNName: "特克斯和凯科斯群岛",
  // },
  // {
  //   code: "tv",
  //   name: "Tuvalu",
  //   dialCode: 688,
  //   phoneFormat: "901234",
  //   CNName: "图瓦卢",
  // },
  // {
  //   code: "us",
  //   name: "United States",
  //   dialCode: 1,
  //   phoneFormat: "(201) 555-0123",
  //   CNName: "美国",
  // },
  // {
  //   code: "gb",
  //   name: "United Kingdom",
  //   dialCode: 44,
  //   phoneFormat: "07400 123456",
  //   CNName: "英国",
  // },
  // {
  //   code: "vi",
  //   name: "U.S. Virgin Islands",
  //   dialCode: 1340,
  //   phoneFormat: "(340) 642-1234",
  //   CNName: "美属维尔京群岛",
  // },
  // {
  //   code: "ug",
  //   name: "Uganda",
  //   dialCode: 256,
  //   phoneFormat: "0712 345678",
  //   CNName: "乌干达",
  // },
  // {
  //   code: "ua",
  //   name: "Ukraine (Україна)",
  //   dialCode: 380,
  //   phoneFormat: "039 123 4567",
  //   CNName: "乌克兰",
  // },
  // {
  //   code: "ae",
  //   name: "United Arab Emirates (‫الإمارات العربية المتحدة‬‎)",
  //   dialCode: 971,
  //   phoneFormat: "050 123 4567",
  //   CNName: "阿拉伯联合酋长国",
  // },
  // {
  //   code: "uy",
  //   name: "Uruguay",
  //   dialCode: 598,
  //   phoneFormat: "094 231 234",
  //   CNName: "乌拉圭",
  // },
  // {
  //   code: "uz",
  //   name: "Uzbekistan (Oʻzbekiston)",
  //   dialCode: 998,
  //   phoneFormat: "8 91 234 56 78",
  //   CNName: "乌兹别克斯坦",
  // },
  // {
  //   code: "vu",
  //   name: "Vanuatu",
  //   dialCode: 678,
  //   phoneFormat: "591 2345",
  //   CNName: "瓦努阿图",
  // },
  // {
  //   code: "va",
  //   name: "Vatican City (Città del Vaticano)",
  //   dialCode: 379,
  //   phoneFormat: "312 345 6789",
  //   CNName: "梵蒂冈城",
  // },
  // {
  //   code: "ve",
  //   name: "Venezuela",
  //   dialCode: 58,
  //   phoneFormat: "0412-1234567",
  //   CNName: "委内瑞拉",
  // },
  // {
  //   code: "wf",
  //   name: "Wallis and Futuna",
  //   dialCode: 681,
  //   phoneFormat: "50 12 34",
  //   CNName: "瓦利斯和富图纳",
  // },
  // {
  //   code: "eh",
  //   name: "Western Sahara (‫الصحراء الغربية‬‎)",
  //   dialCode: 212,
  //   phoneFormat: "0650-123456",
  //   CNName: "西撒哈拉",
  // },
  // {
  //   code: "ye",
  //   name: "Yemen (‫اليمن‬‎)",
  //   dialCode: 967,
  //   phoneFormat: "0712 345 678",
  //   CNName: "也门",
  // },
  // {
  //   code: "zm",
  //   name: "Zambia",
  //   dialCode: 260,
  //   phoneFormat: "095 5123456",
  //   CNName: "赞比亚",
  // },
  // {
  //   code: "zw",
  //   name: "Zimbabwe",
  //   dialCode: 263,
  //   phoneFormat: "071 123 4567",
  //   CNName: "津巴布韦",
  // },
  // {
  //   code: "ax",
  //   name: "Åland Islands",
  //   dialCode: 358,
  //   phoneFormat: "041 2345678",
  //   CNName: "奥兰群岛",
  // },
];
