<template>
  <div
    ref="countryCodeSelector"
    :class="`CountryCodeSelectorContainer ${bordered?'borderedContainer':''}`"
    @click="clickSelect"
  >
    <div class="selectedContainer">
      <div class="selected">
        <span
          :class="`flag flag-${JSON.parse(currentCountry).code} optionFlag ${JSON.parse(currentCountry).code === 'tw'
            ? 'twFlag'
            : ''}`">
        </span>
        <span class="textContainer">
          {{ JSON.parse(currentCountry).code === 'tw'?'台湾 +'+JSON.parse(currentCountry).dialCode:'+'+JSON.parse(currentCountry).dialCode }}
        </span>
      </div>
      <img :src="require('./arrow-down.png')" alt="arrow-down" :class="`icon arrow-down ${openDropdown?'rotate':''}`" @contextmenu="e=>e.preventDefault()">
    </div>
    <div :class="`countryCodeArraySelectMenu ${openDropdown?'show':'hidden'}`">
      <template v-if="searchbar">
        <div :class="`menuSearch`">
          <input
            type="text"
            class="searchInput"
            :placeholder="searchPlaceHolder"
            @keyup="onSearch"
            v-model="search"
          >
          <img :src="require('./search.png')" alt="search" class="icon search" @contextmenu="e=>e.preventDefault()">
        </div>
        <div class="menuDivider"/>
      </template>
      <div class="menuOptionList">
        <div
          :class="`optionsContainer ${JSON.parse(currentCountry).code===country.code?'selectedOptions':''}`"
          :key="index"
          v-for="(country,index) in countryCodeArray"
          @click="e=>handleChange(e,JSON.stringify(country))"
        >
          <span
            :class=" `flag flag-${country.code} optionFlag ${country.code === 'tw'
              ? 'twFlag'
              : ''}`"/>
          <span class="textContainer">
            {{ '+' + country.dialCode + ' ' + country.CNName + ' ' + country.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { countryCodeList } from './countryCode'
import './flags.css'

let delay
let countryCodeArray = [];

export default {
  name: 'CountryCodeSelector',
  components: {
    VNodes: {
      functional: true,
      render: (h, ctx) => ctx.props.vnodes
    }
  },
  props: {
    searchbar: {
      type: Boolean,
      default: false
    },
    countryOptions: {
      type: Array,
      default: []
    },
    countryCode: {
      type: Number,
      default: 0
    },
    searchPlaceHolder: {
      type: String,
      default: '请输入想要搜索的国家名/区号'
    },
    bordered: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      countryCodeArray: [],
      search: '',
      openDropdown: false,
      value: null,
      defaultValue: null
    }
  },
  created () {
    this.countryCodeArray = [];
    if (this.countryOptions.length > 0) {
      this.countryOptions.forEach(item => {
        this.countryCodeArray.push(...countryCodeList.filter(fil => fil.code == item))
      })
    } else {
      this.countryCodeArray = countryCodeList;
    }
    countryCodeArray = this.countryCodeArray;

    const matchCountry = countryCodeArray.find(country => {
      return country.dialCode === this.countryCode
    })

    if (matchCountry) {
      this.value = JSON.stringify(matchCountry)
    } else {
      this.value = null
    }

    this.defaultValue = JSON.stringify(countryCodeArray[0])
  },
  mounted () {
  },
  watch: {
    openDropdown (newVal) {
      if (delay) {
        clearTimeout(delay)
      }
      const dropDownEl = document.getElementsByClassName('countryCodeArraySelectMenu')[0]
      if (newVal === true) {
        if (dropDownEl) {
          dropDownEl.style.display = 'block'
        }
        // 若显示，则监听失去焦点事件。
        document.addEventListener('click', this.click_out_side, true)
      } else {
        delay = setTimeout(() => {
          if (dropDownEl) {
            dropDownEl.style.display = 'none'
          }
          clearTimeout(delay)
        }, 200)
        // 不显示，销毁监听事件。
        document.removeEventListener('click', this.click_out_side, true)
      }
    },
    countryCode (value) {
      const matchCountry = countryCodeArray.find(country => {
        return country.dialCode === value
      })

      if (matchCountry) {
        this.value = JSON.stringify(matchCountry)
      } else {
        this.value = null
      }
    }
  },
  methods: {
    onSearch (e) {
      this.search = e.target.value
      if (e.target.value) {
        const value = e.target.value.toLocaleLowerCase()
        this.countryCodeArray = countryCodeArray.filter(country => {
          const {
            dialCode,
            CNName,
            name,
            code
          } = country || {}
          const matchText = (dialCode + CNName + name + code).toLocaleLowerCase()
          const isMatch = matchText.indexOf(value.trim()) > -1
          return isMatch
        })
      } else {
        this.countryCodeArray = countryCodeArray
      }
    },
    handleChange (e, value) {
      e.stopPropagation()
      this.value = value
      const countryCode = Number(JSON.parse(value).dialCode)
      this.$emit('update:countryCode', countryCode)
      this.openDropdown = false
      this.search = ''
      this.countryCodeArray = countryCodeArray
    },
    click_out_side (e) {
      const selectEl = this.$refs.countryCodeSelector
      const dropDownEl = document.getElementsByClassName('countryCodeArraySelectMenu')[0]
      if (selectEl && dropDownEl) {
        const isSelectContained = selectEl.contains(e.target)
        const isDropDownContained = dropDownEl.contains(e.target)
        const needClose = !(isSelectContained || isDropDownContained)
        if (needClose) {
          this.openDropdown = false
        }
      }
    },
    clickSelect () {
      if (!this.openDropdown) {
        this.openDropdown = true
      }
    }
  },
  computed: {
    options () {
      const h = this.$createElement
      const options = this.countryCodeArray.map((country, index) => {
        const key = JSON.stringify(country)
        return Object.assign(
          {}, {
            value: key,
            key,
            label: h(
              'div',
              { class: 'optionsContainer' },
              [
                h(
                  'span',
                  { class: `flag flag-${country.code} optionFlag ${country.code === 'tw'
                    ? 'twFlag'
                    : ''}` }
                ),
                h(
                  'span',
                  { class: 'textContainer' },
                  [
                    '+' + country.dialCode + ' ' + country.CNName + ' ' + country.name
                  ]
                )
              ])
          }
        )
      })
      return options
    },
    currentCountry () {
      return this.value || this.defaultValue
    }
  }

}
</script>

<style lang="scss" scoped>

.CountryCodeSelectorContainer {
  width: 1.5rem;
  border-radius: 4px;
  transition: all 0.4s;
  position: relative;
  line-height: 1.5 !important;
  text-align: start;
}

.icon {
  width: 16px;
  height: 16px;
}

.arrow-down {
  width: 0.25rem;
  height: 0.25rem;
  margin-left: 5px;
  filter: brightness(0.5);
  opacity: 0.8;
  transition: all 0.4s;
}

.rotate {
  transform: rotate(180deg);
}

.selected {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.countryCodeArraySelectMenu {
  position: absolute;
  width: 240px;
  z-index: 2050;
  border-radius: 4px;
  box-shadow: 0 2px 8px #00000038;
  background: #fff;
  transition: all 0.2s;
}

.show {
  opacity: 1;
  margin-top: 2px;
}

.hidden {
  opacity: 0;
  margin-top: -8px;
  display: none;
}

.menuDivider {
  margin: 4px 0px;
  height: 1px;
  min-width: 100%;
  width: 100%;
  background: #e8e8e8;
}

.borderedContainer {
  border: 1px solid #d9d9d9;
  &:hover {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px #1890ff30;
  }
  &:active {
    border-color: #40a9ff;
    box-shadow: 0 0 0 2px #1890ff30;
  }
}

.optionsContainer {
  padding: 0.16rem 0.2rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  transition: all 400ms;
  cursor: pointer;
  height: 50px;
  &:hover {
    background:#eff7ff
  }
}

.selectedOptions {
   background:#eff7ff;
   font-weight: bold;
}

.selectedContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  transition: all 400ms;
  cursor: pointer;
}

.optionFlag {
  box-shadow: rgb(204,213,222) 0px 2px 3px;
  margin-right: 8px;
  flex: none;
}

.twFlag {
  display: none;
}

.textContainer {
  max-width: 180px;
  width: 100%;
  float: left;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.menuSearch {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 11px;
}

.searchInput {
  border:none !important;
  box-shadow: none !important;
  width: 100%;
  font-size: 14px;
  &:focus {
    border:none !important;
    box-shadow: none !important;
    outline: none;
  }
}

.search {
  filter: grayscale(0);
}

.searchInput:focus + .search {
  filter: grayscale(0);
}

.searchInput::-webkit-input-placeholder {
  font-size: 14px;
  opacity: 0.7;
}

.menuOptionList {
  max-height: 250px;
  overflow: auto;
}

.menuOptionList::-webkit-scrollbar {
  width: 4px;
  height: 8px;
}

.menuOptionList::-webkit-scrollbar-track {
  background: #d7e8fa;
}

.menuOptionList::-webkit-scrollbar-thumb {
  background: #56BEFE;
  border-radius: 4px;
}
</style>
